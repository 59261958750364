import styled from 'styled-components';
import { Wrapper } from '../../styles/mixins';
import SanityContentText from '../sanity/content/SanityContentText';

interface P {
  section: any
}

const HomeSectionText: React.FC<P> = ({ section }: P) => {
  return (
    <ContentText
      link={section?.link}
      notes={section?.notes}
      excerpt={section?.excerpt}
      wrapper={false}
    />
  )
}


const ContentText = styled(SanityContentText)`
  ${Wrapper};
`

export default HomeSectionText
