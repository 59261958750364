import styled from 'styled-components'
import ProductCardList from 'frontend/src/components/product/card/ProductCardList'
import { TypesetBodyM, TypesetHeadingXS } from '../typeset/Typeset'
import { Breakpoint, Columns, MobileBreakpoint, TabletBreakpoint } from '../../styles/functions'
import { TypeBodyM, TypeHeadingXS } from '../../styles/mixins'
import SanityContent from '../sanity/content/SanityContent'

interface Props {
  choice: any
  index: number
  className?: string
}
const Default: React.FC<Props> = ({ 
  choice,
  index,
  className,
}: Props) => {
  return (
    <Container className={className}>
      <Heading>
        <Caption>
          {`${index+1}`.padStart(2,'0')}.
        </Caption>
        <Title layout="DEFAULT" blocks={choice.title} />
      </Heading>
      <Content content={choice.content} />
      <Products layout="XS" products={choice.products} />
    </Container>
  )
}

const Container = styled.div`
`

const Heading = styled.div`
  display: grid;
  grid-template-columns: ${Columns(1)} 1fr;
  margin-bottom: var(--spacing-16);

  ${TabletBreakpoint} {
    grid-template-columns: 32px 1fr;
  }

  ${MobileBreakpoint} {
    grid-template-columns: 40px 1fr;
  }
`

const Caption = styled.div`
  ${TypeBodyM};
  margin-top: 4px;
  display: inline-flex;
`

const Title = styled(SanityContent)`
  ${TypeHeadingXS};
`

const Content = styled(TypesetBodyM)`
  margin-bottom: var(--spacing-24);
`

const Products = styled(ProductCardList)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--spacing-24) var(--gutter) ;

  ${TabletBreakpoint(`
    grid-template-columns: 1fr;
  `)}
`

export default Default