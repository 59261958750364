import SanityContentLanding from '../sanity/content/SanityContentLanding';


const HomeSectionIntroduction: React.FC<HomeSectionProps> = ({ section }: HomeSectionProps) => {

  return (
    <SanityContentLanding
      media={section}
      type={section.type}
      title={section.title}
      link={section.link}
      notes={section?.notes}
      excerpt={section?.excerpt}
      secondary={section?.secondary}
      wrapper={true}
    />
  )
}

export default HomeSectionIntroduction
