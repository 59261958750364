import styled from "styled-components";
import GlobalSlider from "./GlobalSlider";
import SlideshowProvider from "src/contexts/SlideshowProvider";

interface P {
  children: React.ReactNode;
  settings: any;
  [key: string]: any;
}
const GlobalSlideshow: React.FC<P> = ({ children, settings, ...props }: P) => {
  return (
    <SlideshowProvider settings={settings}>
      <Slider {...props}>{children}</Slider>
    </SlideshowProvider>
  );
};

const Slider = styled(GlobalSlider)``;

export default GlobalSlideshow;
