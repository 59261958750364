import { findShopifyProductByRef } from 'library/utils/products';
import { useState } from 'react';
import styled, { css } from 'styled-components'
import { useProducts } from '../../contexts/ProductsProvider';
import { Breakpoint } from '../../styles/functions';
import { TypeBodyM, TypeCaptionM } from '../../styles/mixins';
import { getStyleFromProduct } from 'library/utils/shopify/getters';
import LinkRender from '../link/LinkRender';

const getStyle = (shopifyProduct) => {
  const {style} = getStyleFromProduct(shopifyProduct);
  return style;
}

const getColour = (shopifyProduct) => {
  const {colour} = getStyleFromProduct(shopifyProduct);
  return colour;
}

interface P {
  productGroup: any
  setSelectedProduct: any
  selectedProduct: any
}

const HomeSectionCollectionGroup: React.FC<P> = ({ 
  productGroup,
  selectedProduct,
  setSelectedProduct,
  ...props
}: P) => {
  const { shopifyProducts } = useProducts();

  const combinedProducts = productGroup.products.map((product) => ({
    shopifyProduct: findShopifyProductByRef( shopifyProducts, product._id ),
    product,
  })).filter((_combinedProduct) => {
    return _combinedProduct.shopifyProduct
  })

  if(!combinedProducts.length) return <></>

  const [ combinedProduct, setCombinedProduct, ] = useState(combinedProducts[0]);

  const {
    shopifyProduct,
  } = combinedProduct;

  if(!shopifyProduct) return <></>

  const variant = shopifyProduct.variants.edges[0].node;
  const style = getStyle(shopifyProduct);
  
  const onMouseOver = (_combinedProduct) => {
    setCombinedProduct(_combinedProduct)
    setSelectedProduct(_combinedProduct)
  }

  return (
      <Container {...props}>
        <Definition>
          <Heading>
            No.
          </Heading>
          {variant.sku}
        </Definition>
        <Definition $indent={true}>
          <Heading>
            Style
          </Heading>
          {style}
        </Definition>
        <Definition $indent={true}>
          <Heading>
            Colours
          </Heading>
          <nav>
            {
              combinedProducts.map(( _combinedProduct, index, ) => 
                <Colour 
                  $isActive={_combinedProduct.product._id == selectedProduct.product._id}
                  onMouseOver={() => onMouseOver(_combinedProduct)} 
                  href={`/products/${selectedProduct.product.store.slug.current}`}
                  key={`HomeSectionCollectionGroup__Colour--${index}`}
                >
                  { getColour(_combinedProduct.shopifyProduct) }
                </Colour>
              )
            }
          </nav>
        </Definition>
      </Container>
  )
}

const Container = styled.div`
  ${TypeBodyM}
`

const Definition = styled.div<{ $indent? }>`
  grid-template-columns: 48px 1fr;
  display: grid;

  ${Breakpoint('tablet', 'max')} {
    grid-template-columns: 40px 1fr;
  }
  
  ${props => props.$indent && css`
    margin-left: 48px;

    ${Breakpoint('tablet', 'max')} {
      margin-left: 40px;
    }
  `}

  ${props => !props.$indent && css`
    margin-bottom: 8px;
  `}
`

const Heading = styled.style`
  ${TypeCaptionM};
  display: inline-flex;
`

const Colour = styled(LinkRender)<{$isActive}>`
  cursor: pointer;
  display: block;

  /* &:hover { */
    /* color: var(--color-charcoal400); */
  /* } */

  ${ props => !props.$isActive && css`
    color: var(--color-charcoal400);
  `}
`

export default HomeSectionCollectionGroup