// import styled from 'styled-components'
import HomeSectionIntroduction from 'frontend/src/components/home/HomeSectionIntroduction';
import HomeSectionHighlights from 'frontend/src/components/home/HomeSectionHighlights';
import HomeSectionPacking from 'frontend/src/components/home/HomeSectionPacking';
import HomeSectionLuxury from 'frontend/src/components/home/HomeSectionLuxury';
import HomeSectionClub from 'frontend/src/components/home/HomeSectionClub';
import HomeSectionCollection from './HomeSectionCollection';
import HomeSectionProductCarousel from './HomeSectionProductCarousel';
import HomeSectionProductOverview from './HomeSectionProductOverview';
import HomeSectionText from './HomeSectionText';

interface Props {
  section: any
}

const HomeSectionRender: React.FC<Props> = ({ section }: Props) => {
  switch(section._type) {
    case 'homeText':
      return <HomeSectionText section={section} />
    case 'defaultIntroduction':
      return <HomeSectionIntroduction section={section} />
    case 'collectionIntroduction':
      return <HomeSectionCollection section={section} />
    case 'highlights':
      return <HomeSectionHighlights section={section} />
    case 'productCarousel':
      return <HomeSectionProductCarousel section={section} />
    case 'productOverview':
      return <HomeSectionProductOverview section={section} />
    case 'packing':
      return <HomeSectionPacking section={section} />
    case 'luxury':
      return <HomeSectionLuxury section={section} />
    case 'club':
      return <HomeSectionClub section={section} />
    default:
      return <></>
  }
}
export default HomeSectionRender
