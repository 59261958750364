import { ReactNode } from 'react';
import ButtonPrimary from '../button/ButtonPrimary';

interface Props {
  className?: string,
  href?: string,
  children?: ReactNode,
}

const LinkButton: React.FC<Props> = ({ 
  className, 
  href, 
  children, 
}: Props) => {
    return (
    <ButtonPrimary href={href} className={className}>
      {children}
    </ButtonPrimary>
  )
}

export default LinkButton
