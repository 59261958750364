import styled from 'styled-components';
import { useSettings } from "../../contexts/SettingsProvider";
import { Breakpoint, Columns as Col, DesktopBreakpoint, MobileBreakpoint } from "../../styles/functions";
import { TypeBodyM, TypeCaptionL, TypeCaptionM, TypeHeadingL, Wrapper } from "../../styles/mixins";
import LinkRender from "../link/LinkRender";
import SanityContent from "./content/SanityContent";
import SanityVideo from "../sanity/SanityVideo";

const SanityLuxury: React.FC<any> = ({ 
  // section,
}: any) => {
  const settings = useSettings();
  if(!settings.luxury) return <></>

  const columns = [1,2].map( k => settings.luxury?.[`column${k}`])

  const ratio = settings.luxury.video.aspectRatio.replace(":", "x");

  return (
    <Section>
      <Inner>
      <Link href={settings.luxury.link.href}>
      </Link>
      <Title>{settings.luxury.title}</Title>
      <Columns>
      { columns.map((column, i) => (
        <Column key={`HomeSectionLuxury__Col-${i}`}>
          {
            column.map((row, _i) => (
              <Row key={`HomeSectionLuxury__Row-${_i}`}>
                <RowTitle>{row.title}</RowTitle>
                <RowBody blocks={row.content} layout="DEFAULT" />
              </Row>
              )
            )}
        </Column>
      ))
      }
      </Columns>
      <Mobile>
        <RowBody blocks={settings.luxury.content} layout="DEFAULT" />
      </Mobile>
      <Video ratio="16x9" video={settings.luxury.video} autoplay layout="fill" />
      </Inner>
    </Section>
  )
}

const Section = styled.section`
  ${Wrapper};
  padding-top: var(--spacing-16);
  padding-bottom: var(--spacing-16);
  color: var(--color-background);
  position: relative;
`

const Inner = styled.div`
  position: relative;
  min-height: 700px;
  display: grid;

  ${MobileBreakpoint} {
    min-height: 600px;
  }
`

const Link = styled(LinkRender)`
position: absolute;
left:0;right:0;
top:0;bottom:0;
z-index: 50;
background: transparent;
`

const Title = styled.h2`
  ${TypeHeadingL}
  grid-row: 1 / -1;
  grid-column: 1 / -1;
  z-index: 20;
  position: relative;
  margin: auto;
`

const Video = styled(SanityVideo)`
  grid-row: 1 / -1;
  grid-column: 1 / -1;
  position:relative;
  overflow: hidden;
  &:before {
    content: ' ';
    position: absolute;
    left:0;right:0;
    top:0;bottom:0;
    z-index: 1;
    background-color: var(--color-charcoal);
    opacity: 0.25;
  }
`

const Columns = styled.div`
  position: absolute;
  top: var(--spacing-24);
  left: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 ${Col(1)};
  z-index: 10;
  width: calc(100% - 32px);

  ${DesktopBreakpoint} {
    width: 720px;
  }

  ${MobileBreakpoint} {
    display: none;
  }
`

const Column = styled.div`
`

const Row = styled.div`
  display: grid;
  grid-template-columns: 55px 1fr;
  gap: 0 var(--gutter);
  margin-bottom: 4px;
`


const RowTitle = styled.div`
  ${TypeCaptionM}
`

const RowBody = styled(SanityContent)`
  ${TypeBodyM}

  a {
    ${TypeCaptionL};
    display: block;
    margin-top: var(--spacing-16);
    text-decoration: underline;
  }
`
const Mobile = styled.div`
  ${TypeBodyM};
  color: var(--color-background);
  position: absolute;
  left:16px;right:16px;
  bottom: var(--spacing-24);
  z-index: 20;

  ${Breakpoint('mobile', 'min')} {
    display: none;
  }
`


export default SanityLuxury
