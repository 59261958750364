import styled from "styled-components";
import { Wrapper } from "frontend/src/styles/mixins";
import {
  Breakpoint,
  Columns,
  MobileBreakpoint,
} from "frontend/src/styles/functions";
import { findShopifyProductByRef } from "library/utils/products";
import HomeSectionCollectionGroup from "./HomeSectionCollectionGroup";
import { useProducts } from "../../contexts/ProductsProvider";
import { useState } from "react";
import ProductOverviewCaptions from "../product/ProductOverviewCaptions";
import ProductOverview from "../product/ProductOverview";
import ProductOverlayGallery from "../product/ProductOverlayGallery";
import ButtonPrimary from "../button/ButtonPrimary";
import { AnimatePresence, motion } from "framer-motion";

const HomeSectionProductOverview: React.FC<HomeSectionProps> = ({
  section,
}: HomeSectionProps) => {
  const { productGroups } = section;
  const { shopifyProducts } = useProducts();

  const product = productGroups[0].products[0];
  if (!product) return <></>;

  const shopifyProduct = findShopifyProductByRef(shopifyProducts, product._id);

  if (!shopifyProduct) return <></>;

  const [selectedProduct, setSelectedProduct] = useState({
    product,
    shopifyProduct,
  });

  if (!shopifyProduct) return <></>;

  const [activeDetail, setActiveDetail] = useState(-1);
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);

  return (
    <Section>
      <Groups>
        {productGroups.map((productGroup, index) => (
          <HomeSectionCollectionGroup
            productGroup={productGroup}
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct}
            key={`${section._id}__productGroup--${index}`}
          />
        ))}
        {section.link && (
          <ButtonPrimary
            href={section.link.href}
            style={{ marginRight: "auto" }}
          >
            {section.link.title}
          </ButtonPrimary>
        )}
      </Groups>

      <AnimatePresence>
        <OverviewWrapper
          key={selectedProduct.shopifyProduct?.id}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <Overview
            layout={product.overviewLayout}
            shopifyProduct={selectedProduct.shopifyProduct}
            activeDetail={activeDetail}
            setActiveDetail={setActiveDetail}
            setIsOverlayOpen={setIsOverlayOpen}
          />
        </OverviewWrapper>
      </AnimatePresence>

      <OverviewCaptions
        shopifyProduct={selectedProduct.shopifyProduct}
        setActiveDetail={setActiveDetail}
        activeDetail={activeDetail}
        setIsOverlayOpen={setIsOverlayOpen}
      />

      {isOverlayOpen && (
        <ProductOverlayGallery
          initialIndex={activeDetail}
          shopifyProduct={selectedProduct.shopifyProduct}
          setIsOverlayOpen={setIsOverlayOpen}
        />
      )}
    </Section>
  );
};

const Section = styled.div`
  ${Wrapper}
  margin-bottom: var(--spacing-80);
  margin-top: var(--spacing-80);
  position: relative;

  gap: 0 var(--gutter);

  ${Breakpoint("mobile", "min")} {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

const OverviewWrapper = styled(motion.div)`
  position: relative;
  grid-row: 1 / 3;
  grid-column: 2 / 3;
  display: grid;
`;

const Groups = styled.div`
  margin-bottom: auto;
  margin-right: auto;
  display: grid;
  gap: var(--spacing-16);
`;

const Overview = styled(ProductOverview)`
  grid-row: 1 / 3;
  grid-column: 2 / 3;
  margin-right: auto;

  ${MobileBreakpoint} {
    margin-top: 120px;
    margin-bottom: 24px;
  }
`;

const OverviewCaptions = styled(ProductOverviewCaptions)`
  margin-top: auto;
  ${Breakpoint("mobile", "min")} {
    width: ${Columns(7)};
  }
`;

export default HomeSectionProductOverview;
