import styled from "styled-components";
// import HomeSectionHighlightsCard from './HomeSectionHighlightsCard';
import { Columns } from "../../styles/functions";
import GlobalSlideshow from "../global/GlobalSlideshow";
import ProductCard from "../product/card/ProductCard";

const HomeSectionProductCarousel: React.FC<HomeSectionProps> = ({
  section,
}: HomeSectionProps) => {
  const slideshowSettings = {
    dragFree: true,
    containScroll: "keepSnaps",
    align: "start",
  };

  const key = JSON.stringify(section.products.map((p) => p._key));

  return (
    <Container>
      <GlobalSlideshow settings={slideshowSettings} key={key}>
        {section.products.map((reference) => (
          <CardWrap key={reference._key}>
            <Card layout="L" product={reference.product} />
          </CardWrap>
        ))}
      </GlobalSlideshow>
    </Container>
  );
};

const Container = styled.div`
  margin-top: var(--spacing-24);
  margin-bottom: var(--spacing-40);
`;

const Card = styled(ProductCard)`
  width: ${Columns(8)};
  flex-shrink: 0;
`;

const CardWrap = styled.div`
  padding-left: var(--gutter);
  &:nth-child(1) {
    padding-left: var(--inset);
  }
  &:last-child {
    padding-right: var(--inset);
  }
`;

export default HomeSectionProductCarousel;
