import styled, { css } from 'styled-components'
import { Wrapper,TypeHeadingXL } from 'frontend/src/styles/mixins'
import { Breakpoint, Columns, MobileBreakpoint } from 'frontend/src/styles/functions'

import SanityMedia from '../sanity/SanityMedia';
import { numberToRatio } from 'library/utils/image';
import HomeSectionCollectionGroups from './HomeSectionCollectionGroups';


const HomeSectionCollection: React.FC<HomeSectionProps> = ({ 
  section 
}: HomeSectionProps) => {
  let ratio, isPortrait;

  if(section.type == 'image') {
    const image = section.image;
    const aspectRatio = image.asset?.metadata?.dimensions?.aspectRatio || 1;
    isPortrait = aspectRatio < 1;
    ratio = numberToRatio(aspectRatio);
  }

  if(section.type == 'video') {
    const [w,h] = section.video.aspectRatio.split(':').map((v) => parseFloat(v))
    isPortrait = w < h;
    ratio = `${w}x${h}`;
  }

  const isLivestream = section.type == 'livestream'

  return (
    <Section>
      <SectionMedia 
        $isFullscreen={section.isFullscreen} 
        $isLivestream={isLivestream} 
        $isPortrait={isPortrait} 
        media={section} 
        ratio={ratio}
        autoplay={true}
        layout={section.isFullscreen ? 'fill' : 'responsive'}
      />
      <SectionTitle>{section.title}</SectionTitle>
      <div>
        <HomeSectionCollectionGroups 
          productGroups={section.productGroups} 
          section={section}
        />
      </div>
    </Section>
  )
}


const Section = styled.div`
  ${Wrapper}
  margin-bottom: var(--spacing-80);
  margin-top: var(--spacing-8);
`

const SectionTitle = styled.h1`
  ${TypeHeadingXL};
  white-space: pre-wrap;
  text-align: center;
  margin-top: var(--spacing-40);
  margin-bottom: var(--spacing-80);
`

const SectionMedia = styled(SanityMedia)<{
  $isPortrait: boolean,
  $isFullscreen: boolean,
  $isLivestream: boolean,
}>`
position: relative;
  margin: 0 auto var(--spacing-24);

  ${Breakpoint('mobile', 'min')} {
    ${(props) => {
      if(props.$isLivestream) return css`
        width: 100%;
        padding-top: 16px;
      `

      // Fullscreen no livestream
      if(props.$isFullscreen) return css`
        width: 100%;
        height: calc((100 * var(--1vh)) - var(--header-height) - var(--spacing-24));
      `

      return props.$isPortrait && css`
        width: ${Columns(10)};
      ` || css`
        width: ${Columns(14)};
      `}
    }
  }

  ${MobileBreakpoint} {
    width: 100%;
    ${props => {
      if(props.$isFullscreen) return css`
        height: calc((100 * var(--1vh)) - var(--header-height) - var(--spacing-24));
      `
    }}
  }
`

export default HomeSectionCollection
