import styled, { css } from 'styled-components'
import ProductOverviewImages from './ProductOverviewImages';

interface P {
  layout: "LAYOUT1" | "LAYOUT2" | "LAYOUT3"
  shopifyProduct: any
  activeDetail: number
  setActiveDetail: any
  setIsOverlayOpen: any
  id: any
}

const ProductOverview: React.FC<any> = ({ 
  layout,
  shopifyProduct,
  activeDetail,
  setActiveDetail,
  setIsOverlayOpen,
  ...props
}: any) => {
  const medias = shopifyProduct.media.edges.map((v) => v.node);

  return (
    <Container 
      $layout={layout} 
      onMouseOut={() => setActiveDetail(-1)}
      data-anchor="product-overview" 
      {...props}
    >
      <ProductOverviewImages 
        shopifyProduct={shopifyProduct}
        layout={layout}
        activeDetail={activeDetail}
        onImageMouseOver={(index) => () => setActiveDetail(index)} 
        onImageClick={(index) => () => {
          setActiveDetail(index);
          setIsOverlayOpen(true);
        }}
        medias={medias} 
      />
    </Container>
  )
}

const Container = styled.div<{$layout: P["layout"]}>`
  gap: var(--spacing-16) var(--gutter);
  display: flex;
  flex-wrap: wrap;

`

export default ProductOverview