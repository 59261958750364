import styled from 'styled-components'
import { Wrapper } from 'frontend/src/styles/mixins'
import HomeSectionHighlightsCard from './HomeSectionHighlightsCard';
import { Columns } from '../../styles/functions';


const HomeSectionHighlights: React.FC<HomeSectionProps> = ({ section }: HomeSectionProps) => {
  const {cards, link} = section;
  return (
    <Section>
      <Cards>
        { cards.map((card, index) => (
          <HomeSectionHighlightsCard card={card} key={card._key} />
        )) }
      </Cards>
    </Section>
  )
}


const Section = styled.div`
  ${Wrapper}
  display: flex;
  margin-bottom: var(--spacing-120);
  padding-top: var(--spacing-24);
  flex-direction: column;
`

const Cards = styled.div`
  display: flex;
  margin: 0 auto 0;
  flex-wrap: wrap;
  gap: var(--spacing-120) var(--gutter);
`

export default HomeSectionHighlights
