
import styled from 'styled-components';
import { Wrapper } from "../../styles/mixins";
import SanityClub from "../sanity/SanityClub";

const HomeSectionClub: React.FC<HomeSectionProps> = ({ 
}: HomeSectionProps) => <Club />

const Club = styled(SanityClub)`
  margin: var(--spacing-16) 0;
  ${Wrapper};
`

export default HomeSectionClub
