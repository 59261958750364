import styled, { css } from "styled-components";
import { useIntersection, useMeasure } from "react-use";
import { useEffect, useRef } from "react";
import { BezierDefault, TypeBodyM, TypeCaptionM } from "../../styles/mixins";
import { Breakpoint, MobileBreakpoint } from "../../styles/functions";

interface P {
  setDetailCaptionsHeight?: any;
  setDetailCaptionsIntersect?: any;
  setActiveDetail: any;
  activeDetail: number;
  shopifyProduct: any;
  setIsOverlayOpen: (toggle: boolean) => any;
}

const ProductOverviewCaptions: React.FC<P> = ({
  setActiveDetail,
  activeDetail,
  shopifyProduct,
  setIsOverlayOpen,
  setDetailCaptionsHeight = () => null,
  setDetailCaptionsIntersect = () => null,
  ...props
}: P) => {
  const medias = shopifyProduct.media.edges.map((v) => v.node);
  const [measureRef, { height }] = useMeasure();
  const intersectRef = useRef(null);
  const intersection = useIntersection(intersectRef, {
    root: null,
    rootMargin: "0px",
    threshold: 0,
  });

  useEffect(() => {
    setDetailCaptionsHeight(height + 24);
  }, [height]);

  useEffect(() => {
    setDetailCaptionsIntersect(intersection?.isIntersecting || false);
  }, [intersection]);

  return (
    <Container
      ref={intersectRef}
      onMouseOut={() => setActiveDetail(-1)}
      {...props}
    >
      <Measure ref={measureRef}>
        {medias.map((media, index: number) => (
          <Caption
            $isInactive={activeDetail > -1 && index != activeDetail}
            onMouseOver={() => setActiveDetail(index)}
            key={index}
            onClick={() => setIsOverlayOpen(true)}
          >
            <span>{media.alt}</span>
          </Caption>
        ))}
      </Measure>
    </Container>
  );
};

const RefEl = styled.div<{ ref }>``;

const Container = styled(RefEl)`
  counter-reset: caption;
`;

const Measure = styled(RefEl)``;

const Caption = styled.button<{ $isInactive: boolean }>`
  ${TypeBodyM};
  text-align: left;
  padding-left: 48px;
  position: relative;

  ${MobileBreakpoint} {
    padding-left: 32px;
  }

  span {
    transition: all 0.3s ${BezierDefault};
  }

  &:before {
    ${TypeCaptionM};
    display: inline-flex;
    content: counter(caption, decimal-leading-zero) " ";
    counter-increment: caption;
    position: absolute;
    left: 0;
    top: 2px;
  }

  ${(props) =>
    props.$isInactive &&
    css`
      span {
        opacity: 0.5;
      }
    `}
`;

export default ProductOverviewCaptions;
