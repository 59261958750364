import styled, { css } from "styled-components";
import { Wrapper, TypeHeadingXL, Indent } from "frontend/src/styles/mixins";
import { Breakpoint, Columns } from "frontend/src/styles/functions";
import {
  TypesetHeadingM,
  TypesetHeadingXS,
} from "frontend/src/components/typeset/Typeset";
import SanityMedia from "../../sanity/SanityMedia";
import { numberToRatio } from "library/utils/image";
import SanityButton from "../../sanity/SanityButton";

interface P {
  type: string;
  media: any;
  title: string;
  wrapper: boolean;
  notes?: string;
  link?: any;
  excerpt?: any[];
  secondary?: any[];
  [key: string]: any;
}

const SanityContentLanding: React.FC<P> = ({
  type,
  title,
  media,
  link,
  wrapper = true,
  notes = "",
  excerpt = [],
  secondary = [],
  ...props
}: P) => {
  let ratio, isPortrait;

  if (type == "image") {
    const aspectRatio =
      media.image.asset?.metadata?.dimensions?.aspectRatio || 1;
    isPortrait = aspectRatio < 1;
    ratio = numberToRatio(aspectRatio);
  }

  if (type == "video") {
    const [w, h] = media.video.aspectRatio.split(":").map((v) => parseFloat(v));
    isPortrait = w < h;
    ratio = `${w}x${h}`;
  }

  return (
    <Section $wrapper={wrapper} {...props}>
      <SectionTitle>{title}</SectionTitle>
      <SectionMedia
        $isPortrait={isPortrait}
        media={media}
        ratio={ratio}
        autoplay={true}
      />
      <SectionText
        $isPortrait={isPortrait}
        indent={isPortrait ? 7 : 5}
        caption={notes}
        content={excerpt}
        captionAlign="SUPER"
      />

      {link && <SectionButton link={link} />}
      <SectionSecondary indent={2} content={secondary} />
    </Section>
  );
};

const Section = styled.div<{ $wrapper }>`
  ${(p) => p.$wrapper && Wrapper};
  margin-bottom: var(--spacing-80);
`;

const SectionTitle = styled.h1`
  ${TypeHeadingXL};
  white-space: pre-wrap;
  text-align: center;
  margin-top: var(--spacing-40);
  margin-bottom: var(--spacing-40);
`;

const SectionMedia = styled(SanityMedia)<{ $isPortrait: boolean }>`
  margin: 0 auto var(--spacing-24) !important;

  ${Breakpoint("mobile", "min")} {
    ${(props) =>
      (props.$isPortrait &&
        css`
          width: ${Columns(10)};
        `) ||
      css`
        width: ${Columns(14)};
      `}
  }
`;

const SectionText = styled(TypesetHeadingM)<{ $isPortrait: boolean }>`
  margin-bottom: var(--spacing-40);
`;

const SectionButton = styled(SanityButton)``;

const SectionSecondary = styled(TypesetHeadingXS)`
  margin-top: var(--spacing-40);
  margin-bottom: var(--spacing-40);
  --indent: ${Columns(2)};

  ${Breakpoint("mobile", "min")} {
    margin-right: ${Columns(3, 0)};
    margin-left: ${Columns(11, 0)};
  }
`;

export default SanityContentLanding;
