import styled, { css } from 'styled-components'
import { TypesetHeadingS } from '../typeset/Typeset';
import { DEFAULT_RATIOS } from 'library/config/ratios';
import { findNearestRatio
 } from 'library/utils/image';
import SanityMedia from '../sanity/SanityMedia';
import { Breakpoint, Columns, MobileBreakpoint } from '../../styles/functions';
import { TypeCaptionL } from '../../styles/mixins';
import SanityLinkRender from '../sanity/SanityLinkRender';
interface Props {
  card: any
}

const getVideoRatio = (aspectRatio: string) => {
  const videoRatios = aspectRatio?.split(':') || [1,1]
  return parseInt(videoRatios[0])/parseInt(videoRatios[1])
}

const HomeSectionHighlightsCard: React.FC<Props> = ({ card }: Props) => {
  // find nearest image ratio
  const mediaRatios = DEFAULT_RATIOS

  const mediaRatio = card.type == 'video' ? getVideoRatio(card.video?.aspectRatio) : (card.image?.asset?.metadata.dimensions.aspectRatio || 1)

  
  const ratio = findNearestRatio(mediaRatios, mediaRatio)
  
  const isLarge = card.display == 'large'
  const isPortrait = mediaRatio < 1;


  return (
    <Card link={card.link} $isLarge={isLarge} $isPortrait={isPortrait}>
      <Media 
        media={card} 
        ratio={ratio} 
        autoplay={true}
      />
      
      <Heading>
        { card?.notes && <Caption>{card.notes}</Caption> }
        <Title content={card?.title || []} />
      </Heading>
    </Card>
  )
}


const Card = styled(SanityLinkRender)<{$isLarge, $isPortrait}>`

  ${(props) => !props.$isLarge && props.$isPortrait && css`
    ${Breakpoint('mobile', 'min')} {
      width: ${Columns(12)};
      margin: 0 auto;
    }
    ${MobileBreakpoint} {
      width: ${Columns(6)};
      margin: 0 auto;
    }
  `}

  ${(props) => props.$isLarge && props.$isPortrait && css`
    ${Breakpoint('mobile', 'min')} {
        width: ${Columns(18)};
        margin: 0 ${Columns(3,0)};
      }

      ${MobileBreakpoint} {
        width: ${Columns(12)};
        margin: 0 auto;
      }
  `}

  ${(props) => !props.$isLarge && !props.$isPortrait && css`
    ${Breakpoint('mobile', 'min')} {
      width: ${Columns(12)};
      margin: 0 auto;
    }

    ${MobileBreakpoint} {
      width: ${Columns(6)};
      margin: 0 auto;
    }
  `}

  ${(props) => props.$isLarge && !props.$isPortrait && css`
    /* ${Breakpoint('mobile', 'min')} { */
      width: 100%;
    /* } */
  `}
`


const Media = styled(SanityMedia)`
  margin: 0 auto 8px;
`

const Heading = styled.div`
  display: grid;
  grid-template-columns: ${Columns(4)} 1fr;
  gap: 8px var(--gutter);

  ${MobileBreakpoint} {
    grid-template-columns: 1fr;

  }
`

const Caption = styled.div`
  ${TypeCaptionL};
  margin-top: 4px;
`

const Title = styled(TypesetHeadingS)`
  /* margin: 0 auto 24px; */
`

export default HomeSectionHighlightsCard
