import styled from "styled-components";
import { Wrapper } from "frontend/src/styles/mixins";
import SanityButton from "../sanity/SanityButton";
import { TypesetHeadingS } from "../typeset/Typeset";
import {
  Breakpoint,
  Columns,
  DesktopBreakpoint,
  MobileBreakpoint,
  TabletBreakpoint,
} from "../../styles/functions";
import HomeSectionPackingChoice from "./HomeSectionPackingChoice";

const HomeSectionPacking: React.FC<HomeSectionProps> = ({
  section,
}: HomeSectionProps) => {
  return (
    <Section>
      <Inner>
        <Sticky>
          <TypesetHeadingS
            indent={4}
            content={section?.title || []}
            style={{
              marginBottom: "var(--spacing-16)",
            }}
          />

          <TypesetHeadingS indent={4} content={section?.content || []} />

          {section.link && <Link link={section.link} />}
        </Sticky>
        <Choices>
          {section.choices.map((choice, index) => (
            <Choice choice={choice} index={index} key={choice._key} />
          ))}
        </Choices>
      </Inner>
    </Section>
  );
};

const Section = styled.section`
  ${Wrapper}
  display: flex;
  padding-top: var(--spacing-80);
  padding-bottom: var(--spacing-120);
  flex-direction: column;
`;

const Inner = styled.div`
  ${Breakpoint("mobile", "min")} {
    display: grid;
    margin-right: auto;
    gap: var(--gutter);
  }

  ${TabletBreakpoint(`
    grid-template-columns: ${Columns(16)} ${Columns(6)};
  `)};

  ${DesktopBreakpoint} {
    grid-template-columns: ${Columns(15)} ${Columns(1)} ${Columns(6)};
  }
`;

const Sticky = styled.div`
  top: var(--header-height);
  margin-bottom: auto;

  ${Breakpoint("mobile", "min")} {
    position: sticky;
  }

  ${MobileBreakpoint} {
    margin-bottom: 64px;
  }
`;

const Link = styled(SanityButton)`
  margin: var(--spacing-40) auto 0 0;
`;

const Choices = styled.div`
  display: grid;
  grid-gap: var(--spacing-64);
  grid-column: auto / -1;
`;

const Choice = styled(HomeSectionPackingChoice)``;

export default HomeSectionPacking;
