import { AnimatePresence, motion } from "framer-motion";
import { findShopifyProductByRef } from "library/utils/products";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { useProducts } from "../../contexts/ProductsProvider";
import {
  Breakpoint,
  Columns,
  DesktopBreakpoint,
  MobileBreakpoint,
  TabletBreakpoint,
} from "../../styles/functions";
import ShopifyImage from "../shopify/ShopifyImage";
import HomeSectionCollectionGroup from "./HomeSectionCollectionGroup";
import { getOriginalSrcFromProduct } from "library/utils/shopify/getters";
import {
  ImageFieldsFragment,
  ProductFieldsFragment,
} from "library/services/shopify";
import ShopifyMedia from "../shopify/ShopifyMedia";

interface P {
  productGroups: any;
  section: any;
}

const HomeSectionCollectionGroups: React.FC<P> = ({
  section,
  productGroups,
  ...props
}: P) => {
  const { shopifyProducts } = useProducts();

  if (!productGroups?.length) return <></>;

  const product = productGroups?.[0].products?.[0];
  if (!product) return <></>;
  const shopifyProduct = findShopifyProductByRef(
    shopifyProducts,
    product._id,
  ) as ProductFieldsFragment;

  if (!shopifyProduct) return <></>;

  const [selectedProduct, setSelectedProduct] = useState({
    product,
    shopifyProduct,
  });

  return (
    <Container {...props}>
      <Groups>
        {productGroups.map((productGroup: any, index: number) => (
          <HomeSectionCollectionGroup
            productGroup={productGroup}
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct}
            key={`${section._key}__productGroup--${index}`}
          />
        ))}
      </Groups>

      <Selected>
        <AnimatePresence>
          <ImageWrap
            key={selectedProduct.shopifyProduct?.id}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <ShopifyMedia
              media={selectedProduct.shopifyProduct.media.edges[0].node}
            />
          </ImageWrap>
        </AnimatePresence>
      </Selected>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  ${Breakpoint("mobile", "min")} {
    grid-template-columns: ${Columns(20)} 1fr;
  }
  ${MobileBreakpoint} {
    grid-template-columns: ${Columns(8)} 1fr;
  }
`;

const Groups = styled.div`
  display: grid;
  gap: 8px var(--gutter);
  margin-bottom: auto;

  ${TabletBreakpoint(`
  grid-template-columns: repeat(3, ${Columns(7)});
  `)};

  ${DesktopBreakpoint} {
    grid-template-columns: repeat(4, ${Columns(5)});
  }
`;

const Selected = styled.div`
  margin-left: ${Columns(1, 1)};
  margin-bottom: auto;
  display: grid;

  ${MobileBreakpoint} {
    position: sticky;
    top: var(--spacing-page-top);
  }
`;

const ImageWrap = styled(motion.div)`
  display: grid;
  grid-row: 1 / -1;
  grid-column: 1 / -1;

  > * {
    grid-row: 1 / -1;
    grid-column: 1 / -1;
  }
`;

export default HomeSectionCollectionGroups;
