import styled from "styled-components";
import OverlayGallery from "../overlay/OverlayGallery";
import ShopifyImage from "../shopify/ShopifyImage";

interface P {
  shopifyProduct: any;
  initialIndex: number;
  setIsOverlayOpen: any;
}
const ProductOverlayGallery: React.FC<P> = ({
  shopifyProduct,
  initialIndex,
  setIsOverlayOpen,
}: // ...props
P) => {
  const images = shopifyProduct.media.edges.map((v) => v.node.image);

  const slides = images.map((image, index) => (
    <Slide
      image={image}
      layout="fill"
      objectFit="contain"
      key={`ProductOverlayGallery__Slide--${index}`}
    />
  ));

  const thumbnails = images.map((image, index) => (
    <ShopifyImage
      style={{ marginTop: "8px" }}
      image={image}
      ratio="1x1"
      key={`ProductOverlayGallery__Thumbnail--${index}`}
    />
  ));

  return (
    <OverlayGallery
      slides={slides}
      thumbnails={thumbnails}
      initialIndex={initialIndex}
      setIsOverlayOpen={setIsOverlayOpen}
    />
  );
};

const Slide = styled(ShopifyImage)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

export default ProductOverlayGallery;
