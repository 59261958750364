import styled from 'styled-components'
import { Breakpoint } from '../../styles/functions'
import HomeSectionRender from './HomeSectionRender'

interface HomeProps {
  segment: any
}


const DefaultIntroduction: React.FC<HomeProps> = ({ segment }: HomeProps) => {
  // TODO: Dynamic segment selection

  return (
    <Wrapper>
      {
        segment.sections.map((section) => (
          <Section section={section} key={section._key} />
        ))
      }
    </Wrapper>
  )
}


const Wrapper = styled.div`
  margin-top: var(--header-height); 
  margin-bottom: var(--spacing-80);

  ${Breakpoint('tablet', 'max')} {
    /* display: none; */
  }
`


const Section = styled(HomeSectionRender)`
  margin-bottom: var(--spacing-80);
`

export default DefaultIntroduction
