
import styled from 'styled-components';
import { Wrapper } from "../../styles/mixins";
import SanityLuxury from "../sanity/SanityLuxury";

const HomeSectionLuxury: React.FC<HomeSectionProps> = ({ 
}: HomeSectionProps) => <Luxury />

const Luxury = styled(SanityLuxury)`
  ${Wrapper}
`

export default HomeSectionLuxury
