import styled from 'styled-components'
import UtilWrapper from 'frontend/src/components/util/UtilWrapper';
import { TypeHeadingS } from '../../styles/mixins';
import { DefaultComponentInterface } from '../../types/components';
import { useRouter } from 'next/router';

const PageLoading: DefaultComponentInterface = () => {
  return (
    <Container>
      <Content>
        <p>Loading page...</p>
      </Content>
    </Container>
  )
}

const Container = styled(UtilWrapper)`
  margin-top: calc(var(--spacing-page-top) + 100px);
  margin-bottom: var(--spacing-18);
  text-align: center;
  min-height: 400px;
`

const Content = styled.div`
  ${TypeHeadingS};
`

export default PageLoading
