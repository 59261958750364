import { useState } from "react";
import styled, { css } from "styled-components";
import { useSettings } from "../../contexts/SettingsProvider";
import {
  Breakpoint,
  Columns,
  MobileBreakpoint,
  TabletBreakpoint,
} from "../../styles/functions";
import { ScreenReader, TypeBodyM, TypeHeadingM } from "../../styles/mixins";
import LinkButton from "../link/LinkButton";
import SanityContent from "./content/SanityContent";
import { TypesetHeadingM } from "../typeset/Typeset";
import UtilWrapper from "../util/UtilWrapper";
import SanityImage from "./SanityImage";
import SanityLink from "./SanityLinkRender";

const SanityClub: React.FC<any> = ({}: // section,
any) => {
  const settings = useSettings();
  if (!settings.club) return <></>;
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Section
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Wrapper>
        <Container>
          <Title>{settings.club.title}</Title>
          <Caption layout="CLUB_CAPTION" blocks={settings.club.caption} />
          <ContentWrap>
            <Button href={settings.club.link.href}>
              {settings.club.link.title}
            </Button>
            <DesktopContent
              indent={5}
              content={settings.club.content}
              style={{
                marginRight: Columns(2, 0),
              }}
            />
            <MobileContent indent={5} content={settings.club.mobileContent} />
          </ContentWrap>
        </Container>
        <ImageContainer>
          {/* <Number>01</Number> */}
          <Image
            alt={settings.club.image.alt || ""}
            $isHovered={isHovered}
            image={settings.club.image}
            ratio="2x3"
          />
        </ImageContainer>
        <CoverLink link={settings.club.link}>
          {settings.club.link.title}
        </CoverLink>
      </Wrapper>
    </Section>
  );
};

const Section = styled.section`
  position: relative;
  min-height: 700px;
  display: grid;
  background: var(--color-pistachio);
  margin-top: var(--spacing-16);
  margin-bottom: var(--spacing-16);

  ${TabletBreakpoint(`
    min-height: 800px;
  `)}
`;

const Wrapper = styled(UtilWrapper)`
  display: grid;
`;

const CoverLink = styled(SanityLink)`
  /* ${ScreenReader}; */
  /* position: relative; */
  /* z-index: 10; */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 30;
  .sanity-link__link {
    cursor: pointer;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: transparent;
    text-indent: -9999px;
    overflow: hidden;
    /* z-index: 30; */
    text-align: left;
    opacity: 0;
  }
`;

const Container = styled.div`
  grid-row: 1 / -1;
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: var(--spacing-24);
  padding-bottom: var(--spacing-32);
`;

const ImageContainer = styled.div`
  grid-row: 1 / -1;
  grid-column: 1 / -1;
  padding-top: 100px;
  padding-bottom: 100px;
  display: flex;
  position: relative;
`;

// const Number = styled.div`
//   position: absolute;
//   ${TypeCaptionM};
//   top: 8px;
//   left: 8px;
// `

const Image = styled(SanityImage)<{ $isHovered }>`
  object-fit: contain;
  width: ${Columns(8)};
  margin: auto;
  filter: saturate(0);
  mix-blend-mode: multiply;
  transition: all 0.2s ease;

  ${(props) =>
    props.$isHovered &&
    css`
      filter: saturate(1);
    `}
`;

const Title = styled.h2`
  ${TypeHeadingM};
  color: var(--color-charcoal700);
`;

const Caption = styled(SanityContent)`
  ${TypeBodyM};
  margin-top: auto;
  width: 280px;
  margin-bottom: var(--spacing-16);
`;

const ContentWrap = styled.div`
  position: relative;
  z-index: 20;
`;

const Content = styled(TypesetHeadingM)`
  color: var(--color-charcoal700);
`;

const DesktopContent = styled(Content)`
  ${MobileBreakpoint} {
    display: none;
  }
`;

const MobileContent = styled(Content)`
  ${Breakpoint("mobile", "min")} {
    display: none;
  }
`;

const Button = styled(LinkButton)`
  position: absolute;
  top: 8px;
  z-index: 20;
  --ButtonPrimary-color: var(--color-pistachio);

  ${MobileBreakpoint} {
    position: static;
    margin-bottom: 8px;
  }
`;

export default SanityClub;
